(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin'] = {
  "react": [
    [
      "div",
      "fullScreenOverlay",
      [],
      {},
      [
        "div",
        "fullScreenOverlayContent",
        [],
        {},
        [
          "div",
          "menuBackground",
          [],
          {},
          [
            "div",
            "menuContainer",
            [],
            {}
          ]
        ]
      ]
    ],
    [
      "a",
      "menuButton",
      [],
      {
        "href": "#"
      },
      [
        "svg",
        "iconSVG",
        [
          "_icon"
        ],
        {
          "preserveAspectRatio": "none",
          "viewBox": "0 0 17 17"
        },
        [
          "line",
          null,
          [
            "_line1",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line2",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line3",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ]
      ]
    ]
  ],
  "exports": {
    "tinyMenuMemberSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin"
    },
    "tinyMenuLanguageSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "linewidth": "WIDTH_SIZE",
    "strokewidth": "WIDTH_SIZE",
    "iconcolor": "BG_COLOR_ALPHA",
    "iconcolorSelected": "BG_COLOR_ALPHA",
    "bgDrop": "BG_COLOR_ALPHA",
    "borderColorSelected": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txts": "TEXT_COLOR",
    "separatorHeight": "BORDER_SIZE",
    "separatorColor": "BORDER_COLOR_ALPHA",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderWidthSelected": "BORDER_SIZE",
    "textAlignment": "TEXT_ALIGNMENT",
    "separatorWidthFull": "BORDER_SIZE",
    "fixStrokeBlur": "SIZE",
    "counterColor": "COLOR",
    "bgMembers": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "4px",
    "bg": "color_15",
    "borderwidth": "1px",
    "bordercolor": "color_11",
    "linewidth": "20px",
    "strokewidth": "3",
    "iconcolor": "color_11",
    "iconcolorSelected": "color_11",
    "bgDrop": "color_15",
    "borderColorSelected": "color_11",
    "fnt": "font_1",
    "txt": "color_11",
    "txts": "color_18",
    "separatorHeight": "1px",
    "separatorColor": "color_11",
    "bgOpen": "rgba(0, 0, 0, 0)",
    "borderWidthSelected": "0px",
    "textAlignment": "center",
    "separatorWidthFull": "200px",
    "fixStrokeBlur": [
      "strokewidth"
    ],
    "counterColor": [
      "txt"
    ],
    "bgMembers": "#000000"
  },
  "paramsMutators": {
    "fixStrokeBlur": {
      "type": "eval",
      "value": "(1 - strokewidth % 2) / 2",
      "param": "strokewidth"
    },
    "counterColor": {
      "type": "brightness",
      "value": 2,
      "param": "txt"
    },
    "bgMembers": {
      "type": "alpha",
      "value": 0.1
    }
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);z-index:2;",
    "%menuButton": "[rd]  position:relative;width:100%;height:100%;min-width:40px;min-height:40px;background-color:[bg];border:solid [borderwidth] [bordercolor];cursor:pointer;box-sizing:border-box;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-transform:translateY(0);transform:translateY(0);",
    "%menuButton > %_icon": "width:[linewidth];overflow:visible;stroke-width:[strokewidth];stroke:[iconcolor];height:17px;padding-top:[fixStrokeBlur];stroke-linecap:flat;stroke:[iconcolor];-webkit-transition-duration:150ms;transition-duration:150ms;-webkit-transition-property:stroke;transition-property:stroke;",
    "%menuButton > %_icon%_saveSelectedIconColor": "stroke:[iconcolorSelected];",
    "%menuContainer": "z-index:2;[fnt]  -webkit-box-flex:1;-webkit-flex:1;flex:1;overflow-y:auto;overflow-x:hidden;width:100%;",
    "%menuContainer %_submenu": "display:none;",
    "%menuContainer %_item%_open > %_submenu": "display:block;",
    "%fullScreenOverlay": "pointer-events:none;position:fixed;-webkit-transform:translateX(100%);transform:translateX(100%);-webkit-transition:0.3s -webkit-transform step-end;transition:0.3s -webkit-transform step-end;transition:0.3s transform step-end;transition:0.3s transform step-end, 0.3s -webkit-transform step-end;min-height:100%;",
    "%fullScreenOverlay%_menuOpen": "pointer-events:inherit;-webkit-transform:translateX(0);transform:translateX(0);-webkit-transition:-webkit-transform 0s;transition:-webkit-transform 0s;transition:transform 0s;transition:transform 0s, -webkit-transform 0s;",
    "%menuBackground": "position:relative;-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;box-sizing:border-box;background-color:[bgDrop];-webkit-transition:0.3s opacity;transition:0.3s opacity;opacity:0;width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;",
    "%menuBackground%_menuOpen": "pointer-events:auto;opacity:1;",
    "%menuButton > %_icon %_animating-line": "-webkit-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:150ms;animation-duration:150ms;-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;-webkit-transform-origin:8.5px 0;transform-origin:8.5px 0;",
    "%menuButton%_menuOpen": "border-style:solid;border-color:[borderColorSelected];z-index:1005;background-color:[bgOpen];border-width:[borderWidthSelected];",
    "%menuButton%_menuOpen > %_icon": "stroke:[iconcolorSelected];",
    "%menuButton%_menuOpen > %_icon %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1;animation-name:tiny-menu-open-squeeze-1;",
    "%menuButton%_menuOpen > %_icon %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2;animation-name:tiny-menu-open-squeeze-2;",
    "%menuButton%_menuOpen > %_icon %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3;animation-name:tiny-menu-open-squeeze-3;",
    "%menuButton%_menuOpen%_force-open %_animating-line,%menuButton%_menuOpen%_no-animation %_animating-line": "-webkit-animation-duration:0s;animation-duration:0s;",
    "%menuButton%_menuOpen%_force-open %_line1,%menuButton%_menuOpen%_no-animation %_line1": "-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);",
    "%menuButton%_menuOpen%_force-open %_line2,%menuButton%_menuOpen%_no-animation %_line2": "-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);",
    "%menuButton%_menuOpen%_force-open %_line3,%menuButton%_menuOpen%_no-animation %_line3": "-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);",
    "%menuButton%_menuClosed %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1-reverse;animation-name:tiny-menu-open-squeeze-1-reverse;",
    "%menuButton%_menuClosed %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2-reverse;animation-name:tiny-menu-open-squeeze-2-reverse;",
    "%menuButton%_menuClosed %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3-reverse;animation-name:tiny-menu-open-squeeze-3-reverse;",
    "%menuButton%_menuInitial %_line1": "-webkit-transform:translateY(0.5px);transform:translateY(0.5px);",
    "%menuButton%_menuInitial %_line2": "-webkit-transform:translateY(8.5px);transform:translateY(8.5px);",
    "%menuButton%_menuInitial %_line3": "-webkit-transform:translateY(16.5px);transform:translateY(16.5px);",
    "%menuContainer %_link": "color:[txt];display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-flex:1;-webkit-flex:1 0 auto;flex:1 0 auto;",
    "%menuContainer %_link %_counter": "color:[counterColor];padding-left:0.3rem;",
    "%menuContainer %_link%_current": "color:[txts];",
    "%menuContainer %_firstSection": "padding-top:80px;padding-bottom:20px;",
    "%menuContainer %_nthSection": "padding-top:20px;padding-bottom:20px;",
    "%menuContainer %_memberSection": "background-color:[bgMembers];",
    "%menuContainer %_memberSection %_item": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header %_link": "display:inline-block;-webkit-box-flex:1;-webkit-flex:auto;flex:auto;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;box-sizing:border-box;max-width:100%;",
    "%menuContainer %_memberIconItems": "display:inherit;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_dir-left%menuContainer %_memberIconItems": "padding:0 0 0 10px;",
    "%_dir-right%menuContainer %_memberIconItems": "padding:0 10px 0 0;",
    "%menuContainer %_memberIconItems %_avatar": "border-radius:50%;margin:0 8px 6px 8px;",
    "%menuContainer %_memberIconItems %_icon": "margin:0 10px 6px 10px;font-family:Arial;font-size:10px;font-weight:bold;",
    "%menuContainer %_top-menu": "-webkit-box-flex:1;-webkit-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;letter-spacing:2px;",
    "%menuContainer %_top-menu %_force-scroll": "height:calc(100% + 1px);",
    "%_dir-right%menuContainer %_top-menu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu > %_separator": "border-top:solid [separatorHeight] [separatorColor];display:block;margin:0 20px 0 20px;width:[separatorWidthFull];",
    "%_dir-center%menuContainer %_top-menu > %_item": "padding:0 20px 0 20px;",
    "%menuContainer %_top-menu %_link": "padding:10px 20px;text-align:[textAlignment];",
    "%menuContainer %_top-menu %_header": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%_dir-center%menuContainer %_top-menu %_header": "-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;padding:0 22px 0 22px;",
    "%menuContainer %_top-menu %_header > %_link": "padding-left:0;",
    "%_dir-center%menuContainer %_top-menu %_header %_toggler:after": "margin-left:-22px;width:22px;text-align:right;",
    "%menuContainer %_top-menu %_header %_toggler:after": "content:'\\ff0b';color:[txt];padding:10px 16px 10px 20px;position:relative;display:inline-block;",
    "%menuContainer %_top-menu %_header %_toggler%_current:after": "color:[txts];",
    "%menuContainer %_top-menu %_item%_has-children": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_link": "font-weight:bold;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_header > %_toggler:after": "content:\"\\ff0d\";",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_submenu": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%menuContainer %_top-menu %_item%_has-children %_submenu": "display:none;font-size:90%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu > %_item + %_item > %_link:before": "left:calc(50% - [separatorWidthFull] / 2);",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}",
    "@keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}"
  }
}
 skins['wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin'] = {
  "react": [
    [
      "div",
      "languageSection",
      [],
      {
        "ref": "languageSection"
      },
      [
        "div",
        "element",
        [],
        {},
        [
          "select",
          "languageSelect",
          [],
          {}
        ],
        [
          "div",
          "flag-container",
          [],
          {},
          [
            "img",
            "flagImg",
            [],
            {
              "alt": ""
            }
          ],
          [
            "span",
            "countryCode",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "placeholder",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "txt": "TEXT_COLOR_LEGACY_ALPHA"
  },
  "paramsDefaults": {
    "txt": "color_11"
  },
  "css": {
    "%element": "padding:0 17px;line-height:41px;height:45px;width:100%;box-sizing:border-box;position:absolute;bottom:0;background-color:rgba(0, 0, 0, 0.1);overflow:hidden;color:[txt];",
    "%element select": "border:none;width:100%;height:100%;z-index:10;position:absolute;left:0;top:0;opacity:0;font-size:25px;",
    "%flag-container": "height:45px;text-align:center;",
    "%flag-container img": "height:18px;padding-right:7px;vertical-align:middle;",
    "%flag-container span": "font-family:avenir;font-size:16px;vertical-align:middle;display:inline-block;margin-top:2px;",
    "%placeholder": "width:0;height:45px;"
  }
}
 skins['wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin'] = {
  "react": [],
  "exports": {
    "button": {
      "skin": "wysiwyg.viewer.skins.LoginSocialButtonSkin"
    },
    "avatar": {
      "skin": "wysiwyg.viewer.skins.IconSkin"
    }
  },
  "css": {}
}
 skins['wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin'] = {
  "react": [
    [
      "div",
      "fullScreenOverlay",
      [],
      {},
      [
        "div",
        "fullScreenOverlayContent",
        [],
        {},
        [
          "div",
          "menuBackground",
          [],
          {},
          [
            "div",
            "menuContainer",
            [],
            {}
          ]
        ]
      ]
    ],
    [
      "a",
      "menuButton",
      [],
      {
        "href": "#"
      },
      [
        "svg",
        "iconSVG",
        [
          "_icon"
        ],
        {
          "preserveAspectRatio": "none",
          "viewBox": "0 0 17 17"
        },
        [
          "line",
          null,
          [
            "_line1",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line2",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line3",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ]
      ]
    ]
  ],
  "exports": {
    "tinyMenuMemberSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin"
    },
    "tinyMenuLanguageSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "linewidth": "WIDTH_SIZE",
    "strokewidth": "WIDTH_SIZE",
    "iconcolor": "BG_COLOR_ALPHA",
    "iconcolorSelected": "BG_COLOR_ALPHA",
    "bgDrop": "BG_COLOR_ALPHA",
    "borderColorSelected": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txts": "TEXT_COLOR",
    "separatorHeight": "BORDER_SIZE",
    "separatorColor": "BORDER_COLOR_ALPHA",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderWidthSelected": "BORDER_SIZE",
    "textAlignment": "TEXT_ALIGNMENT",
    "separatorWidth": "BORDER_SIZE",
    "fixStrokeBlur": "SIZE",
    "counterColor": "COLOR",
    "bgMembers": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "4px",
    "bg": "color_15",
    "borderwidth": "1px",
    "bordercolor": "color_11",
    "linewidth": "20px",
    "strokewidth": "3",
    "iconcolor": "color_11",
    "iconcolorSelected": "color_11",
    "bgDrop": "color_15",
    "borderColorSelected": "color_11",
    "fnt": "font_1",
    "txt": "color_11",
    "txts": "color_18",
    "separatorHeight": "1px",
    "separatorColor": "color_11",
    "bgOpen": "color_15",
    "borderWidthSelected": "1px",
    "textAlignment": "left",
    "separatorWidth": "140px",
    "fixStrokeBlur": [
      "strokewidth"
    ],
    "counterColor": [
      "txt"
    ],
    "bgMembers": "#000000"
  },
  "paramsMutators": {
    "fixStrokeBlur": {
      "type": "eval",
      "value": "(1 - strokewidth % 2) / 2",
      "param": "strokewidth"
    },
    "counterColor": {
      "type": "brightness",
      "value": 2,
      "param": "txt"
    },
    "bgMembers": {
      "type": "alpha",
      "value": 0.1
    }
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);z-index:2;",
    "%menuButton": "[rd]  position:relative;width:100%;height:100%;min-width:40px;min-height:40px;background-color:[bg];border:solid [borderwidth] [bordercolor];cursor:pointer;box-sizing:border-box;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-transform:translateY(0);transform:translateY(0);",
    "%menuButton > %_icon": "width:[linewidth];overflow:visible;stroke-width:[strokewidth];stroke:[iconcolor];height:17px;padding-top:[fixStrokeBlur];stroke-linecap:flat;stroke:[iconcolor];-webkit-transition-duration:150ms;transition-duration:150ms;-webkit-transition-property:stroke;transition-property:stroke;",
    "%menuButton > %_icon%_saveSelectedIconColor": "stroke:[iconcolorSelected];",
    "%menuContainer": "z-index:2;[fnt]  -webkit-box-flex:1;-webkit-flex:1;flex:1;overflow-y:auto;overflow-x:hidden;width:100%;max-width:100%;box-sizing:border-box;",
    "%menuContainer %_submenu": "display:none;",
    "%menuContainer %_item%_open > %_submenu": "display:block;",
    "%fullScreenOverlay": "pointer-events:none;position:fixed;-webkit-transform:translateX(100%);transform:translateX(100%);-webkit-transition:0.3s -webkit-transform step-end;transition:0.3s -webkit-transform step-end;transition:0.3s transform step-end;transition:0.3s transform step-end, 0.3s -webkit-transform step-end;min-height:100%;",
    "%fullScreenOverlay%_menuOpen": "pointer-events:inherit;-webkit-transform:translateX(0);transform:translateX(0);-webkit-transition:-webkit-transform 0s;transition:-webkit-transform 0s;transition:transform 0s;transition:transform 0s, -webkit-transform 0s;",
    "%menuBackground": "position:relative;-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;box-sizing:border-box;background-color:[bgDrop];-webkit-transition:0.3s opacity step-start, 0.3s -webkit-transform ease-in;transition:0.3s opacity step-start, 0.3s -webkit-transform ease-in;transition:0.3s transform ease-in, 0.3s opacity step-start;transition:0.3s transform ease-in, 0.3s opacity step-start, 0.3s -webkit-transform ease-in;opacity:0;-webkit-transform:translateX(-100%);transform:translateX(-100%);height:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;width:60%;",
    "%menuBackground%_menuOpen": "pointer-events:auto;opacity:1;-webkit-transform:none;transform:none;",
    "%menuButton > %_icon %_animating-line": "-webkit-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:150ms;animation-duration:150ms;-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;-webkit-transform-origin:8.5px 0;transform-origin:8.5px 0;",
    "%menuButton%_menuOpen": "border-style:solid;border-color:[borderColorSelected];z-index:1005;background-color:[bgOpen];border-width:[borderWidthSelected];",
    "%menuButton%_menuOpen > %_icon": "stroke:[iconcolorSelected];",
    "%menuButton%_menuOpen > %_icon %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1;animation-name:tiny-menu-open-squeeze-1;",
    "%menuButton%_menuOpen > %_icon %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2;animation-name:tiny-menu-open-squeeze-2;",
    "%menuButton%_menuOpen > %_icon %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3;animation-name:tiny-menu-open-squeeze-3;",
    "%menuButton%_menuOpen%_force-open %_animating-line,%menuButton%_menuOpen%_no-animation %_animating-line": "-webkit-animation-duration:0s;animation-duration:0s;",
    "%menuButton%_menuOpen%_force-open %_line1,%menuButton%_menuOpen%_no-animation %_line1": "-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);",
    "%menuButton%_menuOpen%_force-open %_line2,%menuButton%_menuOpen%_no-animation %_line2": "-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);",
    "%menuButton%_menuOpen%_force-open %_line3,%menuButton%_menuOpen%_no-animation %_line3": "-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);",
    "%menuButton%_menuClosed %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1-reverse;animation-name:tiny-menu-open-squeeze-1-reverse;",
    "%menuButton%_menuClosed %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2-reverse;animation-name:tiny-menu-open-squeeze-2-reverse;",
    "%menuButton%_menuClosed %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3-reverse;animation-name:tiny-menu-open-squeeze-3-reverse;",
    "%menuButton%_menuInitial %_line1": "-webkit-transform:translateY(0.5px);transform:translateY(0.5px);",
    "%menuButton%_menuInitial %_line2": "-webkit-transform:translateY(8.5px);transform:translateY(8.5px);",
    "%menuButton%_menuInitial %_line3": "-webkit-transform:translateY(16.5px);transform:translateY(16.5px);",
    "%menuContainer %_link": "color:[txt];display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-flex:1;-webkit-flex:1 0 auto;flex:1 0 auto;",
    "%menuContainer %_link %_counter": "color:[counterColor];padding-left:0.3rem;",
    "%menuContainer %_link%_current": "color:[txts];",
    "%menuContainer %_firstSection": "padding-top:80px;padding-bottom:20px;",
    "%menuContainer %_nthSection": "padding-top:20px;padding-bottom:20px;",
    "%menuContainer %_memberSection": "background-color:[bgMembers];",
    "%menuContainer %_memberSection %_item": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header %_link": "display:inline-block;-webkit-box-flex:1;-webkit-flex:auto;flex:auto;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;box-sizing:border-box;max-width:100%;",
    "%menuContainer %_memberIconItems": "display:inherit;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_dir-left%menuContainer %_memberIconItems": "padding:0 0 0 10px;",
    "%_dir-right%menuContainer %_memberIconItems": "padding:0 10px 0 0;",
    "%menuContainer %_memberIconItems %_avatar": "border-radius:50%;margin:0 8px 6px 8px;",
    "%menuContainer %_memberIconItems %_icon": "margin:0 10px 6px 10px;font-family:Arial;font-size:10px;font-weight:bold;",
    "%menuContainer %_top-menu": "-webkit-box-flex:1;-webkit-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;letter-spacing:2px;text-align:left;",
    "%menuContainer %_top-menu %_force-scroll": "height:calc(100% + 1px);",
    "%_dir-right%menuContainer %_top-menu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu > %_separator": "border-top:solid [separatorHeight] [separatorColor];display:block;margin:0 20px 0 20px;width:[separatorWidth];",
    "%_dir-center%menuContainer %_top-menu > %_item": "padding:0 20px 0 20px;",
    "%menuContainer %_top-menu %_link": "padding:10px 20px;text-align:[textAlignment];",
    "%menuContainer %_top-menu %_header": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%_dir-center%menuContainer %_top-menu %_header": "-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;padding:0 22px 0 22px;",
    "%menuContainer %_top-menu %_header > %_link": "padding-left:0;",
    "%_dir-center%menuContainer %_top-menu %_header %_toggler:after": "margin-left:-22px;width:22px;text-align:right;",
    "%menuContainer %_top-menu %_header %_toggler:after": "content:'\\ff0b';color:[txt];padding:10px 16px 10px 20px;position:relative;display:inline-block;",
    "%menuContainer %_top-menu %_header %_toggler%_current:after": "color:[txts];",
    "%menuContainer %_top-menu %_item%_has-children": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_link": "font-weight:bold;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_header > %_toggler:after": "content:\"\\ff0d\";",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_submenu": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%menuContainer %_top-menu %_item%_has-children %_submenu": "display:none;font-size:90%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%fullScreenOverlayContent": "overflow:hidden;",
    "%menuBackground%_menuClosed": "-webkit-transition:0.3s ease-in;transition:0.3s ease-in;",
    "%menuContainer %_top-menu > %_item + %_item > %_link:before": "left:0;width:[separatorWidth];",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}",
    "@keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}"
  }
}
 skins['wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin'] = {
  "react": [
    [
      "div",
      "fullScreenOverlay",
      [],
      {},
      [
        "div",
        "fullScreenOverlayContent",
        [],
        {},
        [
          "div",
          "menuBackground",
          [],
          {},
          [
            "div",
            "menuContainer",
            [],
            {}
          ]
        ]
      ]
    ],
    [
      "a",
      "menuButton",
      [],
      {
        "href": "#"
      },
      [
        "svg",
        "iconSVG",
        [
          "_icon"
        ],
        {
          "preserveAspectRatio": "none",
          "viewBox": "0 0 17 17"
        },
        [
          "line",
          null,
          [
            "_line1",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line2",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line3",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ]
      ]
    ]
  ],
  "exports": {
    "tinyMenuMemberSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin"
    },
    "tinyMenuLanguageSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "linewidth": "WIDTH_SIZE",
    "strokewidth": "WIDTH_SIZE",
    "iconcolor": "BG_COLOR_ALPHA",
    "iconcolorSelected": "BG_COLOR_ALPHA",
    "bgDrop": "BG_COLOR_ALPHA",
    "borderColorSelected": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txts": "TEXT_COLOR",
    "separatorHeight": "BORDER_SIZE",
    "separatorColor": "BORDER_COLOR_ALPHA",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderWidthSelected": "BORDER_SIZE",
    "textAlignment": "TEXT_ALIGNMENT",
    "separatorWidth": "BORDER_SIZE",
    "fixStrokeBlur": "SIZE",
    "counterColor": "COLOR",
    "bgMembers": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "4px",
    "bg": "color_15",
    "borderwidth": "1px",
    "bordercolor": "color_11",
    "linewidth": "20px",
    "strokewidth": "3",
    "iconcolor": "color_11",
    "iconcolorSelected": "color_11",
    "bgDrop": "color_15",
    "borderColorSelected": "color_11",
    "fnt": "font_1",
    "txt": "color_11",
    "txts": "color_18",
    "separatorHeight": "1px",
    "separatorColor": "color_11",
    "bgOpen": "color_15",
    "borderWidthSelected": "1px",
    "textAlignment": "right",
    "separatorWidth": "140px",
    "fixStrokeBlur": [
      "strokewidth"
    ],
    "counterColor": [
      "txt"
    ],
    "bgMembers": "#000000"
  },
  "paramsMutators": {
    "fixStrokeBlur": {
      "type": "eval",
      "value": "(1 - strokewidth % 2) / 2",
      "param": "strokewidth"
    },
    "counterColor": {
      "type": "brightness",
      "value": 2,
      "param": "txt"
    },
    "bgMembers": {
      "type": "alpha",
      "value": 0.1
    }
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);z-index:2;",
    "%menuButton": "[rd]  position:relative;width:100%;height:100%;min-width:40px;min-height:40px;background-color:[bg];border:solid [borderwidth] [bordercolor];cursor:pointer;box-sizing:border-box;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-transform:translateY(0);transform:translateY(0);",
    "%menuButton > %_icon": "width:[linewidth];overflow:visible;stroke-width:[strokewidth];stroke:[iconcolor];height:17px;padding-top:[fixStrokeBlur];stroke-linecap:flat;stroke:[iconcolor];-webkit-transition-duration:150ms;transition-duration:150ms;-webkit-transition-property:stroke;transition-property:stroke;",
    "%menuButton > %_icon%_saveSelectedIconColor": "stroke:[iconcolorSelected];",
    "%menuContainer": "z-index:2;[fnt]  -webkit-box-flex:1;-webkit-flex:1;flex:1;overflow-y:auto;overflow-x:hidden;width:100%;",
    "%menuContainer %_submenu": "display:none;",
    "%menuContainer %_item%_open > %_submenu": "display:block;",
    "%fullScreenOverlay": "pointer-events:none;position:fixed;-webkit-transform:translateX(100%);transform:translateX(100%);-webkit-transition:0.3s -webkit-transform step-end;transition:0.3s -webkit-transform step-end;transition:0.3s transform step-end;transition:0.3s transform step-end, 0.3s -webkit-transform step-end;min-height:100%;",
    "%fullScreenOverlay%_menuOpen": "pointer-events:inherit;-webkit-transform:translateX(0);transform:translateX(0);-webkit-transition:-webkit-transform 0s;transition:-webkit-transform 0s;transition:transform 0s;transition:transform 0s, -webkit-transform 0s;",
    "%menuBackground": "position:relative;-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;box-sizing:border-box;background-color:[bgDrop];-webkit-transition:0.3s opacity step-start, 0.3s -webkit-transform ease-in;transition:0.3s opacity step-start, 0.3s -webkit-transform ease-in;transition:0.3s transform ease-in, 0.3s opacity step-start;transition:0.3s transform ease-in, 0.3s opacity step-start, 0.3s -webkit-transform ease-in;opacity:0;-webkit-transform:translateX(134%);transform:translateX(134%);width:60%;height:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:right;-webkit-justify-content:right;justify-content:right;",
    "%menuBackground%_menuOpen": "pointer-events:auto;opacity:1;-webkit-transform:none;transform:none;-webkit-transform:translateX(67%);transform:translateX(67%);",
    "%menuButton > %_icon %_animating-line": "-webkit-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:150ms;animation-duration:150ms;-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;-webkit-transform-origin:8.5px 0;transform-origin:8.5px 0;",
    "%menuButton%_menuOpen": "border-style:solid;border-color:[borderColorSelected];z-index:1005;background-color:[bgOpen];border-width:[borderWidthSelected];",
    "%menuButton%_menuOpen > %_icon": "stroke:[iconcolorSelected];",
    "%menuButton%_menuOpen > %_icon %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1;animation-name:tiny-menu-open-squeeze-1;",
    "%menuButton%_menuOpen > %_icon %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2;animation-name:tiny-menu-open-squeeze-2;",
    "%menuButton%_menuOpen > %_icon %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3;animation-name:tiny-menu-open-squeeze-3;",
    "%menuButton%_menuOpen%_force-open %_animating-line,%menuButton%_menuOpen%_no-animation %_animating-line": "-webkit-animation-duration:0s;animation-duration:0s;",
    "%menuButton%_menuOpen%_force-open %_line1,%menuButton%_menuOpen%_no-animation %_line1": "-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);",
    "%menuButton%_menuOpen%_force-open %_line2,%menuButton%_menuOpen%_no-animation %_line2": "-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);",
    "%menuButton%_menuOpen%_force-open %_line3,%menuButton%_menuOpen%_no-animation %_line3": "-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);",
    "%menuButton%_menuClosed %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1-reverse;animation-name:tiny-menu-open-squeeze-1-reverse;",
    "%menuButton%_menuClosed %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2-reverse;animation-name:tiny-menu-open-squeeze-2-reverse;",
    "%menuButton%_menuClosed %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3-reverse;animation-name:tiny-menu-open-squeeze-3-reverse;",
    "%menuButton%_menuInitial %_line1": "-webkit-transform:translateY(0.5px);transform:translateY(0.5px);",
    "%menuButton%_menuInitial %_line2": "-webkit-transform:translateY(8.5px);transform:translateY(8.5px);",
    "%menuButton%_menuInitial %_line3": "-webkit-transform:translateY(16.5px);transform:translateY(16.5px);",
    "%menuContainer %_link": "color:[txt];display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-flex:1;-webkit-flex:1 0 auto;flex:1 0 auto;",
    "%menuContainer %_link %_counter": "color:[counterColor];padding-left:0.3rem;",
    "%menuContainer %_link%_current": "color:[txts];",
    "%menuContainer %_firstSection": "padding-top:80px;padding-bottom:20px;",
    "%menuContainer %_nthSection": "padding-top:20px;padding-bottom:20px;",
    "%menuContainer %_memberSection": "background-color:[bgMembers];",
    "%menuContainer %_memberSection %_item": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header %_link": "display:inline-block;-webkit-box-flex:1;-webkit-flex:auto;flex:auto;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;box-sizing:border-box;max-width:100%;",
    "%menuContainer %_memberIconItems": "display:inherit;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_dir-left%menuContainer %_memberIconItems": "padding:0 0 0 10px;",
    "%_dir-right%menuContainer %_memberIconItems": "padding:0 10px 0 0;",
    "%menuContainer %_memberIconItems %_avatar": "border-radius:50%;margin:0 8px 6px 8px;",
    "%menuContainer %_memberIconItems %_icon": "margin:0 10px 6px 10px;font-family:Arial;font-size:10px;font-weight:bold;",
    "%menuContainer %_top-menu": "-webkit-box-flex:1;-webkit-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;letter-spacing:2px;",
    "%menuContainer %_top-menu %_force-scroll": "height:calc(100% + 1px);",
    "%_dir-right%menuContainer %_top-menu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu > %_separator": "border-top:solid [separatorHeight] [separatorColor];display:block;margin:0 20px 0 20px;width:[separatorWidth];",
    "%_dir-center%menuContainer %_top-menu > %_item": "padding:0 20px 0 20px;",
    "%menuContainer %_top-menu %_link": "padding:10px 20px;text-align:[textAlignment];",
    "%menuContainer %_top-menu %_header": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%_dir-center%menuContainer %_top-menu %_header": "-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;padding:0 22px 0 22px;",
    "%menuContainer %_top-menu %_header > %_link": "padding-left:0;",
    "%_dir-center%menuContainer %_top-menu %_header %_toggler:after": "margin-left:-22px;width:22px;text-align:right;",
    "%menuContainer %_top-menu %_header %_toggler:after": "content:'\\ff0b';color:[txt];padding:10px 16px 10px 20px;position:relative;display:inline-block;",
    "%menuContainer %_top-menu %_header %_toggler%_current:after": "color:[txts];",
    "%menuContainer %_top-menu %_item%_has-children": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_link": "font-weight:bold;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_header > %_toggler:after": "content:\"\\ff0d\";",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_submenu": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%menuContainer %_top-menu %_item%_has-children %_submenu": "display:none;font-size:90%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%fullScreenOverlayContent": "overflow:hidden;",
    "%menuBackground%_menuClosed": "-webkit-transition:0.3s ease-in;transition:0.3s ease-in;",
    "%menuContainer %_top-menu > %_item + %_item > %_link:before": "width:[separatorWidth];left:calc(100% - [separatorWidth]);",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}",
    "@keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}"
  }
}
 skins['wysiwyg.viewer.skins.mobile.TinyMenuPullFromTopSkin'] = {
  "react": [
    [
      "div",
      "fullScreenOverlay",
      [],
      {},
      [
        "div",
        "fullScreenOverlayContent",
        [],
        {},
        [
          "div",
          "menuBackground",
          [],
          {},
          [
            "div",
            "menuContainer",
            [],
            {}
          ]
        ]
      ]
    ],
    [
      "a",
      "menuButton",
      [],
      {
        "href": "#"
      },
      [
        "svg",
        "iconSVG",
        [
          "_icon"
        ],
        {
          "preserveAspectRatio": "none",
          "viewBox": "0 0 17 17"
        },
        [
          "line",
          null,
          [
            "_line1",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line2",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ],
        [
          "line",
          null,
          [
            "_line3",
            "_animating-line"
          ],
          {
            "x2": "100%"
          }
        ]
      ]
    ]
  ],
  "exports": {
    "tinyMenuMemberSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin"
    },
    "tinyMenuLanguageSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "linewidth": "WIDTH_SIZE",
    "strokewidth": "WIDTH_SIZE",
    "iconcolor": "BG_COLOR_ALPHA",
    "iconcolorSelected": "BG_COLOR_ALPHA",
    "bgDrop": "BG_COLOR_ALPHA",
    "borderColorSelected": "BORDER_COLOR_ALPHA",
    "fnt": "FONT",
    "txt": "TEXT_COLOR",
    "txts": "TEXT_COLOR",
    "separatorHeight": "BORDER_SIZE",
    "separatorColor": "BORDER_COLOR_ALPHA",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderWidthSelected": "BORDER_SIZE",
    "textAlignment": "TEXT_ALIGNMENT",
    "separatorWidthFull": "BORDER_SIZE",
    "fixStrokeBlur": "SIZE",
    "counterColor": "COLOR",
    "bgMembers": "BG_COLOR"
  },
  "paramsDefaults": {
    "rd": "4px",
    "bg": "color_15",
    "borderwidth": "1px",
    "bordercolor": "color_11",
    "linewidth": "20px",
    "strokewidth": "3",
    "iconcolor": "color_11",
    "iconcolorSelected": "color_11",
    "bgDrop": "color_15",
    "borderColorSelected": "color_11",
    "fnt": "font_1",
    "txt": "color_11",
    "txts": "color_18",
    "separatorHeight": "1px",
    "separatorColor": "color_11",
    "bgOpen": "rgba(0, 0, 0, 0)",
    "borderWidthSelected": "0px",
    "textAlignment": "center",
    "separatorWidthFull": "200px",
    "fixStrokeBlur": [
      "strokewidth"
    ],
    "counterColor": [
      "txt"
    ],
    "bgMembers": "#000000"
  },
  "paramsMutators": {
    "fixStrokeBlur": {
      "type": "eval",
      "value": "(1 - strokewidth % 2) / 2",
      "param": "strokewidth"
    },
    "counterColor": {
      "type": "brightness",
      "value": 2,
      "param": "txt"
    },
    "bgMembers": {
      "type": "alpha",
      "value": 0.1
    }
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);z-index:2;",
    "%menuButton": "[rd]  position:relative;width:100%;height:100%;min-width:40px;min-height:40px;background-color:[bg];border:solid [borderwidth] [bordercolor];cursor:pointer;box-sizing:border-box;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-transform:translateY(0);transform:translateY(0);",
    "%menuButton > %_icon": "width:[linewidth];overflow:visible;stroke-width:[strokewidth];stroke:[iconcolor];height:17px;padding-top:[fixStrokeBlur];stroke-linecap:flat;stroke:[iconcolor];-webkit-transition-duration:150ms;transition-duration:150ms;-webkit-transition-property:stroke;transition-property:stroke;",
    "%menuButton > %_icon%_saveSelectedIconColor": "stroke:[iconcolorSelected];",
    "%menuContainer": "z-index:2;[fnt]  -webkit-box-flex:1;-webkit-flex:1;flex:1;overflow-y:auto;overflow-x:hidden;width:100%;",
    "%menuContainer %_submenu": "display:none;",
    "%menuContainer %_item%_open > %_submenu": "display:block;",
    "%fullScreenOverlay": "pointer-events:none;position:fixed;-webkit-transform:translateX(100%);transform:translateX(100%);-webkit-transition:0.3s -webkit-transform step-end;transition:0.3s -webkit-transform step-end;transition:0.3s transform step-end;transition:0.3s transform step-end, 0.3s -webkit-transform step-end;",
    "%fullScreenOverlay%_menuOpen": "pointer-events:inherit;-webkit-transform:translateX(0);transform:translateX(0);-webkit-transition:-webkit-transform 0s;transition:-webkit-transform 0s;transition:transform 0s;transition:transform 0s, -webkit-transform 0s;",
    "%menuBackground": "position:relative;-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;box-sizing:border-box;background-color:[bgDrop];-webkit-transition:0.3s opacity step-start, 0.3s -webkit-transform ease-in;transition:0.3s opacity step-start, 0.3s -webkit-transform ease-in;transition:0.3s transform ease-in, 0.3s opacity step-start;transition:0.3s transform ease-in, 0.3s opacity step-start, 0.3s -webkit-transform ease-in;opacity:0;-webkit-transform:translateY(-100%);transform:translateY(-100%);width:100%;height:60%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;",
    "%menuBackground%_menuOpen": "pointer-events:auto;opacity:1;-webkit-transform:none;transform:none;",
    "%menuButton > %_icon %_animating-line": "-webkit-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:150ms;animation-duration:150ms;-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;-webkit-transform-origin:8.5px 0;transform-origin:8.5px 0;",
    "%menuButton%_menuOpen": "border-style:solid;border-color:[borderColorSelected];z-index:1005;background-color:[bgOpen];border-width:[borderWidthSelected];",
    "%menuButton%_menuOpen > %_icon": "stroke:[iconcolorSelected];",
    "%menuButton%_menuOpen > %_icon %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1;animation-name:tiny-menu-open-squeeze-1;",
    "%menuButton%_menuOpen > %_icon %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2;animation-name:tiny-menu-open-squeeze-2;",
    "%menuButton%_menuOpen > %_icon %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3;animation-name:tiny-menu-open-squeeze-3;",
    "%menuButton%_menuOpen%_force-open %_animating-line,%menuButton%_menuOpen%_no-animation %_animating-line": "-webkit-animation-duration:0s;animation-duration:0s;",
    "%menuButton%_menuOpen%_force-open %_line1,%menuButton%_menuOpen%_no-animation %_line1": "-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);",
    "%menuButton%_menuOpen%_force-open %_line2,%menuButton%_menuOpen%_no-animation %_line2": "-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);",
    "%menuButton%_menuOpen%_force-open %_line3,%menuButton%_menuOpen%_no-animation %_line3": "-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);",
    "%menuButton%_menuClosed %_line1": "-webkit-animation-name:tiny-menu-open-squeeze-1-reverse;animation-name:tiny-menu-open-squeeze-1-reverse;",
    "%menuButton%_menuClosed %_line2": "-webkit-animation-name:tiny-menu-open-squeeze-2-reverse;animation-name:tiny-menu-open-squeeze-2-reverse;",
    "%menuButton%_menuClosed %_line3": "-webkit-animation-name:tiny-menu-open-squeeze-3-reverse;animation-name:tiny-menu-open-squeeze-3-reverse;",
    "%menuButton%_menuInitial %_line1": "-webkit-transform:translateY(0.5px);transform:translateY(0.5px);",
    "%menuButton%_menuInitial %_line2": "-webkit-transform:translateY(8.5px);transform:translateY(8.5px);",
    "%menuButton%_menuInitial %_line3": "-webkit-transform:translateY(16.5px);transform:translateY(16.5px);",
    "%menuContainer %_link": "color:[txt];display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-flex:1;-webkit-flex:1 0 auto;flex:1 0 auto;",
    "%menuContainer %_link %_counter": "color:[counterColor];padding-left:0.3rem;",
    "%menuContainer %_link%_current": "color:[txts];",
    "%menuContainer %_firstSection": "padding-top:80px;padding-bottom:20px;",
    "%menuContainer %_nthSection": "padding-top:20px;padding-bottom:20px;",
    "%menuContainer %_memberSection": "background-color:[bgMembers];",
    "%menuContainer %_memberSection %_item": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header": "max-width:100%;",
    "%menuContainer %_memberSection %_item %_header %_link": "display:inline-block;-webkit-box-flex:1;-webkit-flex:auto;flex:auto;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;box-sizing:border-box;max-width:100%;",
    "%menuContainer %_memberIconItems": "display:inherit;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_dir-left%menuContainer %_memberIconItems": "padding:0 0 0 10px;",
    "%_dir-right%menuContainer %_memberIconItems": "padding:0 10px 0 0;",
    "%menuContainer %_memberIconItems %_avatar": "border-radius:50%;margin:0 8px 6px 8px;",
    "%menuContainer %_memberIconItems %_icon": "margin:0 10px 6px 10px;font-family:Arial;font-size:10px;font-weight:bold;",
    "%menuContainer %_top-menu": "-webkit-box-flex:1;-webkit-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;letter-spacing:2px;text-align:center;",
    "%menuContainer %_top-menu %_force-scroll": "height:calc(100% + 1px);",
    "%_dir-right%menuContainer %_top-menu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu > %_separator": "border-top:solid [separatorHeight] [separatorColor];display:block;margin:0 20px 0 20px;width:[separatorWidthFull];",
    "%_dir-center%menuContainer %_top-menu > %_item": "padding:0 20px 0 20px;",
    "%menuContainer %_top-menu %_link": "padding:10px 20px;text-align:[textAlignment];",
    "%menuContainer %_top-menu %_header": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;",
    "%_dir-center%menuContainer %_top-menu %_header": "-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;padding:0 22px 0 22px;",
    "%menuContainer %_top-menu %_header > %_link": "padding-left:0;",
    "%_dir-center%menuContainer %_top-menu %_header %_toggler:after": "margin-left:-22px;width:22px;text-align:right;",
    "%menuContainer %_top-menu %_header %_toggler:after": "content:'\\ff0b';color:[txt];padding:10px 16px 10px 20px;position:relative;display:inline-block;",
    "%menuContainer %_top-menu %_header %_toggler%_current:after": "color:[txts];",
    "%menuContainer %_top-menu %_item%_has-children": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_link": "font-weight:bold;",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_header > %_toggler:after": "content:\"\\ff0d\";",
    "%menuContainer %_top-menu %_item%_has-children%_open > %_submenu": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%menuContainer %_top-menu %_item%_has-children %_submenu": "display:none;font-size:90%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%_dir-right%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:end;-webkit-align-items:flex-end;align-items:flex-end;",
    "%_dir-left%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;",
    "%_dir-center%menuContainer %_top-menu %_item%_has-children %_submenu": "-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%fullScreenOverlayContent": "overflow:hidden;",
    "%menuBackground%_menuClosed": "-webkit-transition:0.3s ease-in;transition:0.3s ease-in;",
    "%menuContainer %_top-menu > %_item + %_item > %_link:before": "left:calc(50% - [separatorWidthFull] / 2);",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1": "0%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@keyframes %tiny-menu-open-squeeze-1-reverse": "100%{-webkit-transform:translateY(0.5px);transform:translateY(0.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(45deg);transform:translateY(8.5px) rotate(45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2": "0%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@keyframes %tiny-menu-open-squeeze-2-reverse": "100%{-webkit-transform:translateY(16.5px);transform:translateY(16.5px);}50%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px) rotate(-45deg);transform:translateY(8.5px) rotate(-45deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@keyframes %tiny-menu-open-squeeze-3": "0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}100%{-webkit-transform:translateY(8.5px) rotateX(90deg);transform:translateY(8.5px) rotateX(90deg);}",
    "@-webkit-keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}",
    "@keyframes %tiny-menu-open-squeeze-3-reverse": "100%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}0%{-webkit-transform:translateY(8.5px);transform:translateY(8.5px);}"
  }
}
 skins['wysiwyg.viewer.skins.mobile.TinyMenuSkin'] = {
  "react": [
    [
      "a",
      "menuButton",
      [],
      {
        "href": "#"
      },
      [
        "svg",
        null,
        [
          "_open",
          "_icon"
        ],
        {
          "preserveAspectRatio": "none",
          "viewBox": "0 0 17 17"
        },
        [
          "line",
          null,
          [],
          {
            "x2": "17",
            "y1": "0.5",
            "y2": "0.5"
          }
        ],
        [
          "line",
          null,
          [],
          {
            "x2": "17",
            "y1": "8.5",
            "y2": "8.5"
          }
        ],
        [
          "line",
          null,
          [],
          {
            "x2": "17",
            "y1": "16.5",
            "y2": "16.5"
          }
        ]
      ],
      [
        "svg",
        null,
        [
          "_close",
          "_icon"
        ],
        {
          "viewBox": "14.25 14.25 21.5 21.5"
        },
        [
          "line",
          null,
          [],
          {
            "x1": "35.75",
            "y1": "14.25",
            "x2": "14.25",
            "y2": "35.75"
          }
        ],
        [
          "line",
          null,
          [],
          {
            "x1": "14.25",
            "y1": "14.25",
            "x2": "35.75",
            "y2": "35.75"
          }
        ]
      ]
    ],
    [
      "div",
      "menuContainer",
      [],
      {}
    ]
  ],
  "exports": {
    "tinyMenuMemberSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuMemberSectionSkin"
    },
    "tinyMenuLanguageSection": {
      "skin": "wysiwyg.viewer.skins.mobile.TinyMenuLanguageSectionSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "linewidth": "WIDTH_SIZE",
    "strokewidth": "WIDTH_SIZE",
    "iconcolor": "BG_COLOR_ALPHA",
    "iconcolorSelected": "BG_COLOR_ALPHA",
    "bgDrop": "BG_COLOR_ALPHA",
    "bgs": "BG_COLOR_ALPHA",
    "txts": "TEXT_COLOR_LEGACY_ALPHA",
    "txt": "TEXT_COLOR_LEGACY_ALPHA",
    "fixStrokeBlur": "SIZE",
    "counterColor": "COLOR"
  },
  "paramsDefaults": {
    "rd": "4px",
    "bg": "color_15",
    "borderwidth": "1px",
    "bordercolor": "color_11",
    "linewidth": "20px",
    "strokewidth": "3",
    "iconcolor": "color_11",
    "iconcolorSelected": "color_11",
    "bgDrop": "color_15",
    "bgs": "color_18",
    "txts": "color_11",
    "txt": "color_11",
    "fixStrokeBlur": [
      "strokewidth"
    ],
    "counterColor": [
      "txt"
    ]
  },
  "paramsMutators": {
    "fixStrokeBlur": {
      "type": "eval",
      "value": "(1 - strokewidth % 2) / 2",
      "param": "strokewidth"
    },
    "counterColor": {
      "type": "brightness",
      "value": 2,
      "param": "txt"
    }
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);z-index:2;",
    "%menuButton": "[rd]  position:relative;width:100%;height:100%;min-width:40px;min-height:40px;background-color:[bg];border:solid [borderwidth] [bordercolor];cursor:pointer;box-sizing:border-box;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-transform:translateY(0);transform:translateY(0);",
    "%menuButton > %_icon": "width:[linewidth];overflow:visible;stroke-width:[strokewidth];stroke:[iconcolor];height:17px;padding-top:[fixStrokeBlur];display:none;",
    "%menuButton > %_icon%_saveSelectedIconColor": "stroke:[iconcolorSelected];",
    "%menuContainer": "z-index:2;position:absolute;font-size:16px;overflow-y:auto;-webkit-transform:translateX(120%);transform:translateX(120%);visibility:hidden;-webkit-transition:300ms -webkit-transform step-end;transition:300ms -webkit-transform step-end;transition:300ms transform step-end;transition:300ms transform step-end, 300ms -webkit-transform step-end;left:20px;width:280px;",
    "%menuContainer %_submenu": "display:none;",
    "%menuContainer %_item%_open > %_submenu": "display:block;",
    "%menuButton%_preserve-legacy-size": "width:50px;height:50px;",
    "%menuButton > %_icon%_open": "display:block;",
    "%_menuOpen%menuButton > %_icon%_open": "display:none;",
    "%_menuOpen%menuButton > %_icon%_close": "display:block;display:block;",
    "%menuContainer%_menuOpen": "visibility:visible;-webkit-transform:translateX(0);transform:translateX(0);-webkit-transition:-webkit-transform 0s;transition:-webkit-transform 0s;transition:transform 0s;transition:transform 0s, -webkit-transform 0s;",
    "%menuContainer %_top-menu": "-webkit-transition:-webkit-transform 300ms ease-in;transition:-webkit-transform 300ms ease-in;transition:transform 300ms ease-in;transition:transform 300ms ease-in, -webkit-transform 300ms ease-in;-webkit-transform:translate3d(0, -105%, 0);transform:translate3d(0, -105%, 0);border:1px solid [bordercolor];overflow-x:hidden;list-style:none;",
    "%_menuOpen%menuContainer %_top-menu": "-webkit-transition:-webkit-transform 300ms ease-in;transition:-webkit-transform 300ms ease-in;transition:transform 300ms ease-in;transition:transform 300ms ease-in, -webkit-transform 300ms ease-in;-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);",
    "%menuContainer %_top-menu %_toggler": "width:50px;height:48px;display:block;position:absolute;top:0;background:[bgDrop];",
    "%menuContainer %_top-menu %_submenu": "clear:both;",
    "%menuContainer %_top-menu %_submenu %_item": "background-color:[bgDrop];",
    "%_dir-left%menuContainer %_top-menu %_submenu %_link": "padding-left:34px;",
    "%menuContainer %_top-menu %_link%_current": "background-color:[bgs];color:[txts];",
    "%_dir-left%menuContainer %_top-menu %_has-children > %_link": "padding-right:67px;padding-left:17px;",
    "%_dir-center%menuContainer %_top-menu %_has-children > %_link": "padding:0 17px;",
    "%_dir-right%menuContainer %_top-menu %_has-children > %_link": "padding-left:67px;padding-right:17px;",
    "%_dir-left%menuContainer %_top-menu %_has-children %_toggler": "border-left:1px solid [bordercolor];right:0;",
    "%_dir-left%menuContainer %_top-menu %_has-children %_toggler:after": "width:50px;text-align:center;line-height:48px;content:\"∨\";-webkit-transform:rotate(0deg);transform:rotate(0deg);position:absolute;color:[txt];font-size:30px;",
    "%_dir-right%menuContainer %_top-menu %_has-children %_toggler": "border-right:1px solid [bordercolor];left:0;",
    "%_dir-right%menuContainer %_top-menu %_has-children %_toggler:before": "width:50px;text-align:center;line-height:48px;content:\"∨\";-webkit-transform:rotate(0deg);transform:rotate(0deg);position:absolute;color:[txt];font-size:30px;left:0;",
    "%_dir-right%menuContainer %_top-menu %_has-children %_toggler:after": "content:\"\";",
    "%_dir-center%menuContainer %_top-menu %_has-children %_toggler:after": "width:50px;text-align:center;line-height:48px;content:\"∨\";-webkit-transform:rotate(0deg);transform:rotate(0deg);position:absolute;color:[txt];font-size:30px;right:0;",
    "%menuContainer %_top-menu %_item": "opacity:0.95;height:48px;line-height:48px;background-color:[bgDrop];border-bottom:1px solid [bordercolor];cursor:pointer;position:relative;color:[txt];",
    "%menuContainer %_top-menu %_item:last-child": "border-bottom:0;",
    "%menuContainer %_top-menu %_item%_open": "height:auto;",
    "%menuContainer %_top-menu %_item%_open > %_link": "border-bottom:1px solid [bordercolor];",
    "%menuContainer %_top-menu %_item%_open > %_toggler": "background:[bgs];",
    "%_dir-right%menuContainer %_top-menu %_item%_open > %_submenu %_link": "padding-right:34px;",
    "%_dir-center%menuContainer %_top-menu %_item%_open > %_submenu %_link": "padding:0;",
    "%menuContainer %_top-menu %_item > %_link": "padding:0 17px;float:left;width:100%;display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;box-sizing:border-box;color:[txt];",
    "%menuContainer %_top-menu %_item > %_link %_counter": "color:[counterColor];padding-left:0.3rem;",
    "%menuContainer %_top-menu %_item > %_link%_current": "color:[txts];",
    "%_dir-left%menuContainer %_top-menu %_item > %_link": "text-align:left;",
    "%_dir-right%menuContainer %_top-menu %_item > %_link": "text-align:right;",
    "%_dir-center%menuContainer %_top-menu %_item > %_link": "text-align:center;",
    "%menuContainer %_webkit-overflow-scrolling-touch": "-webkit-overflow-scrolling:touch;"
  }
}

        return skins;
    }));