define([
    'santa-components',
    'lodash',
    'componentsCore',
    'tinyMenu/components/tinyMenuItem',
    'tinyMenu/components/tinyMenuMemberSection',
    'tinyMenu/components/tinyMenuLanguageSection',
    'reactDOM',
    'skins',
    'tinyMenu/skins/skins.json'
], function (
    santaComponents,
    _,
    componentsCore,
    TinyMenuItem,
    TinyMenuMemberSection,
    TinyMenuLanguageSection,
    ReactDOM,
    skinsPackage,
    skinsJson
) {
    'use strict';

    const HAMMER_CONFIG = {
        cssProps: {
            touchCallout: 'default'
        },
        touchAction: 'auto'
    };

    /**
     * @class components.TinyMenu
     * @extends {core.skinBasedComp}
     */
    const tinyMenu = {
        displayName: 'TinyMenu',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            compData: santaComponents.santaTypesDefinitions.Component.compData,

            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
            id: santaComponents.santaTypesDefinitions.Component.id,
            style: santaComponents.santaTypesDefinitions.Component.style,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            skin: santaComponents.santaTypesDefinitions.Component.skin,
            textAlignment: santaComponents.santaTypesDefinitions.Component.styleParam.textAlignment,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId,
            siteWidth: santaComponents.santaTypesDefinitions.siteWidth,
            forceOverflowScroll: santaComponents.santaTypesDefinitions.BrowserFlags.forceOverflowScroll,
            animateTinyMenuIcon: santaComponents.santaTypesDefinitions.BrowserFlags.animateTinyMenuIcon,
            anchorChangeEvent: santaComponents.santaTypesDefinitions.SiteAspects.anchorChangeEvent,
            windowResizeEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowResizeEvent,
            siteScrollingBlocker: santaComponents.santaTypesDefinitions.SiteAspects.siteScrollingBlocker,
            isTinyMenuOpenAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isTinyMenuOpenAllowed,
            menuItems: santaComponents.santaTypesDefinitions.Menu.menuItems,
            siteMenuWithRender: santaComponents.santaTypesDefinitions.Menu.siteMenuWithRender,
            activeAnchorId: santaComponents.santaTypesDefinitions.AnchorChange.activeAnchorId,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            multilingualIsEnabled: santaComponents.santaTypesDefinitions.RendererModel.multilingual.isEnabled,
            ios: santaComponents.santaTypesDefinitions.BrowserFlags.ios.isRequired,
            getTranslatedAriaLabel: santaComponents.santaTypesDefinitions.Accessibility.getTranslatedAriaLabel.isRequired
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(TinyMenuMemberSection),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(TinyMenuLanguageSection)
        ),

        getMenuItemOptions() {
            const isClassicSkin = this.isClassicSkin();
            // TODO when MOBX will work - use this.props.activeAnchorId instead of this.state.currentAnchor
            return {
                styleId: this.props.styleId,
                currentPage: this.props.currentUrlPageId,
                clickCallback: this.onMouseClick,
                currentAnchor: this.state.currentAnchor,
                timestamp: this.timestamp,
                useSeparators: !isClassicSkin,
                scrollIntoView: !isClassicSkin
            };
        },

        getMenuContainerSkinPart() {
            const options = this.getMenuItemOptions();
            const menuContainer = [];
            const memberData = this.getMemberData();
            const hasMemberSection = !!memberData;
            if (hasMemberSection) {
                const memberSection = this.getMemberSection(memberData, options);
                menuContainer.push(memberSection);
            }
            const siteMenu = this.getSiteMenuSection(this.items, options, !hasMemberSection);
            menuContainer.push(siteMenu);

            if (this.props.multilingualIsEnabled && (_.size(this.props.languages) > 1)) {
                const languageSection = this.getLanguageSection(options);
                menuContainer.push(languageSection);
            }

            return menuContainer;
        },

        getSiteMenuSection(items, options, isFirstSection) {
            const children = TinyMenuItem.buildChildren(items, options);
            return santaComponents.utils.createReactElement('ul', {
                children,
                className: this.classSet({
                    'top-menu': true,
                    'open': this.isMenuOpen(),
                    'force-scroll': this.forceMenuItemsScroll,
                    'firstSection': isFirstSection,
                    'nthSection': !isFirstSection,
                    'webkit-overflow-scrolling-touch': true
                }),
                ref: 'menuItems',
                id: `${this.props.id}menuItems`
            });
        },

        getMemberSection(memberData, options) {
            const memberItemOptions = _.assign({}, options, {scrollIntoView: false});
            return this.createChildComponent(memberData, 'wysiwyg.viewer.components.mobile.TinyMenuMemberSection', 'tinyMenuMemberSection', {
                ref: 'tinyMenuMemberSection',
                id: `${this.props.id}tinyMenuMemberSection`,
                data: memberData,
                textAlignment: this.props.textAlignment,
                sectionClassName: this.classSet({
                    'top-menu': true,
                    'open': this.isMenuOpen(),
                    'force-scroll': this.forceMenuItemsScroll,
                    'firstSection': true,
                    'memberSection': true
                }),
                options: memberItemOptions,
                mergeIconItemsIntoMenuItems: this.isClassicSkin()
            });
        },

        getLanguageSection(options) {
            const languageItemOptions = _.assign({}, options, {scrollIntoView: false});
            const children = this.createChildComponent({}, 'wysiwyg.viewer.components.mobile.TinyMenuLanguageSection', 'tinyMenuLanguageSection', {
                ref: 'tinyMenuLanguageSection',
                id: `${this.props.id}tinyMenuLanguageSection`,
                textAlignment: this.props.textAlignment,
                sectionClassName: this.classSet({
                    'top-menu': true,
                    'open': this.isMenuOpen(),
                    'force-scroll': this.forceMenuItemsScroll,
                    'lastSection': true,
                    'languageSection': true
                }),
                options: languageItemOptions,
                mergeIconItemsIntoMenuItems: this.isClassicSkin()
            });
            return children;
        },

        getMemberData() {
            const memberData = _.get(this.props.compData, ['loginSocialBarRef']);
            if (_.isEmpty(memberData)) {
                return null;
            }

            return _.assign({}, memberData, {
                menuItemsRef: this.props.memberMenuItems,
                iconItemsRef: this.props.memberIconItems
            });
        },

        isForcedOpen() {
            return _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState() === 'open';
        },

        getMenuState() {
            return this.isForcedOpen() ? 'menuOpen' : this.state.menuState;
        },

        isMenuOpen() {
            return this.getMenuState() === 'menuOpen';
        },

        getInitialState() {
            return {
                menuState: 'menuInitial',
                timestamp: new Date(),
                visibility: 'visible'
            };
        },

        onOrientationChange() {
            this.registerReLayout();
            this.closeMenu();
        },

        onAnchorChange(newAnchor) {
            const newAnchorId = newAnchor.activeAnchorComp.id;
            if (newAnchorId !== this.state.currentAnchor) {
                this.setState({currentAnchor: newAnchorId});
            }
        },

        onMouseClick() {
            if (this.isMenuOpen()) {
                this.closeMenu();
            } else {
                this.showMenu();
            }
        },

        onMenuButtonClick(e) {
            this.onMouseClick();

            e.stopPropagation();
            e.preventDefault();
        },

        onMenuStateChange() {
            this.registerReLayout();
            this.forceUpdate();
        },

        closeMenu() {
            this.timestamp = new Date();
            if (!this.isMenuOpen()) {
                return;
            }

            this.setState({menuState: 'menuClosed'}, function () {
                this.onMenuStateChange();
            }.bind(this));
        },

        showMenu() {
            if (!this.isEmpty) {
                this.setState({menuState: 'menuOpen'}, this.onMenuStateChange);
            }
        },

        updateProps(props) {
            this.items = props.menuItems ? props.menuItems : props.siteMenuWithRender;
            this.isEmpty = _.every(this.items, {isVisibleMobile: false});
            if (this.isClassicSkin()) {
                const schemaVersion = _.get(this.props, ['compProp', 'metaData', 'schemaVersion']);
                this.preserveLegacySize = !schemaVersion || schemaVersion === '1.0';
            }

            this.siteWidth = props.siteWidth;
            this.forceMenuItemsScroll = props.forceOverflowScroll;
            this.animateSVG = props.animateTinyMenuIcon;
        },

        componentWillMount() {
            this.props.windowResizeEvent.registerToOrientationChange(this);
            this.props.anchorChangeEvent.registerToAnchorChange(this);
            this.updateProps(this.props);
        },

        isFixed() {
            return this.props.style.position === 'fixed';
        },

        isClassicSkin() {
            return this.props.skin === 'wysiwyg.viewer.skins.mobile.TinyMenuSkin';
        },

        shouldBlockSiteScrolling() {
            return (!this.isClassicSkin() || this.isFixed()) && this.isMenuOpen();
        },

        componentWillReceiveProps(nextProps) {
            if (this.props.currentUrlPageId !== nextProps.currentUrlPageId || // eslint-disable-line no-mixed-operators
                this.isMenuOpen() && !nextProps.isTinyMenuOpenAllowed) { // eslint-disable-line no-mixed-operators
                this.closeMenu();
            }

            this.updateProps(nextProps);
        },

        componentDidUpdate() {
            const isOpen = this.isMenuOpen();
            if (isOpen) {
                ReactDOM.findDOMNode(this.refs.menuContainer).scrollTop = 0;
            }

            this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, this.shouldBlockSiteScrolling());
        },

        getSkinDirection() {
            switch (this.props.skin) {
                case 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin':
                    return 'RIGHT';
                case 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin':
                    return 'LEFT';
                default:
                    return undefined;
            }
        },

        componentDidMount() {
            this.props.siteScrollingBlocker.registerScrollBlockedListener({
                handleUnblockedBy: this.display,
                handleBlockedBy: this.hide
            });

            this.props.siteScrollingBlocker.registerScrollingLayer(this.refs.menuContainer);

            const direction = this.getSkinDirection();
            if (this.props.isMobileDevice && !!direction) {
                this.registerGesture(direction);
            }
        },

        registerGesture(leftOrRight) {
            requirejs(['hammer'], Hammer => {
                this.hammertime = new Hammer.Manager(this.refs[''], HAMMER_CONFIG);
                const event = leftOrRight === 'RIGHT' ? 'swiperight' : 'swipeleft';
                this.hammertime.add(new Hammer.Swipe());
                this.hammertime.on(event, () => this.closeMenu());
            });
        },

        display(originator) {
            if (originator !== this) {
                this.setState({visibility: 'visible'});
            }
        },

        hide(originator) {
            if (originator !== this) {
                this.setState({visibility: 'hidden'});
            }
        },

        getRootStyle() {
            const position = this.getRootPosition ? {position: this.getRootPosition(this.props.style)} : {};
            const touchAction = this.props.ios() === true ? {touchAction: 'manipulation'} : {};

            return _.assign({
                visibility: this.state.visibility
            }, position, touchAction, this.isFixed() && {marginLeft: `calc(50% - ${this.props.siteWidth / 2}px)`});
        },

        getDirection() {
            const directionFromCompProps = _.get(this, ['props', 'compProp', 'direction'], 'left');
            return this.props.textAlignment || directionFromCompProps;
        },

        closeMenuIfClickedOutsideOfMenuContent(event) {
            const clickedOutsideOnMenuContent = event.target.id === `${this.props.id}fullScreenOverlayContent`;
            if (this.isMenuOpen() && clickedOutsideOnMenuContent) {
                this.closeMenu();
            }
        },

        getAriaLabel() {
            const MENU_ARIA_LABEL_KEY = 'dropDownMenu_AriaLabel_TopLevel_SiteNavigation';
            return this.props.getTranslatedAriaLabel('AriaLabels', MENU_ARIA_LABEL_KEY, 'Site');
        },

        getSkinProperties() {
            const isOpen = this.isMenuOpen();
            const classic = this.isClassicSkin();
            const fixed = this.isFixed();
            const stateClass = {};

            const dir = `dir-${this.getDirection()}`;

            const menuContainer = isOpen ? this.getMenuContainerSkinPart() : [];

            const extraDataAttributes = {};

            if (classic) {
                stateClass['preserve-legacy-size'] = this.preserveLegacySize;
                extraDataAttributes['data-classic'] = true;

                if (fixed) {
                    this.props.style.zIndex = isOpen ? 1000 : 1;
                }
            }

            stateClass[this.getMenuState()] = true;
            stateClass[dir] = true;

            return {
                '': _.assign({
                    id: this.props.id,
                    key: `${this.props.id}_${this.props.skin}`,
                    ref: this.props.id,
                    className: 'hidden-on-warmup',
                    style: this.getRootStyle(),
                    tagName: 'nav',
                    'aria-label': this.getAriaLabel()
                }, extraDataAttributes),

                fullScreenOverlay: {
                    ref: `${this.props.id}fullScreenOverlay`,
                    onClick: this.closeMenuIfClickedOutsideOfMenuContent,
                    className: `fullScreenOverlay ${this.classSet(stateClass)}`,
                    style: {top: 0}
                },

                fullScreenOverlayContent: {
                    className: 'fullScreenOverlayContent',
                    style: {width: this.siteWidth, top: 0}
                },

                menuBackground: {
                    className: this.classSet(stateClass)
                },

                menuButton: {
                    ref: `${this.props.id}menuButton`,
                    onClick: this.onMenuButtonClick,
                    className: this.classSet(_.assign({
                        'preserve-legacy-size': this.preserveLegacySize,
                        'force-open': this.isForcedOpen(),
                        'no-animation': !this.animateSVG
                    }, stateClass))
                },

                iconSVG: {
                    preserveAspectRatio: isOpen ? '' : 'none'
                },

                menuContainer: _.assign({
                    children: menuContainer,
                    className: this.classSet(stateClass)
                }, classic && {
                    style: {
                        marginLeft: -_.get(this.props.structure, 'layout.x', 0)
                    }
                })
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.mobile.TinyMenu', tinyMenu);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return tinyMenu;
});
