define(['santa-components', 'lodash', 'prop-types', 'create-react-class', 'reactDOM', 'coreUtils'], function (santaComponents, _, PropTypes, createReactClass, ReactDOM, coreUtils) {
    'use strict';

    const cssUtils = coreUtils.cssUtils;

    function getPageId() {
        return _.get(this, ['link', 'pageId', 'id']);
    }

    function getState(props) {
        props = props || this.props;
        const curPage = props.options.currentPage;
        const isSubMenuOpen = _.some(props.menuItem.items, function (currSubItem) {
            const pageId = getPageId.call(currSubItem);
            return pageId === curPage;
        });

        return {isSubMenuOpen};
    }

    function buildChildren(items, options) { //eslint-disable-line react/display-name
        function isCurrentPage(e) { return e.isCurrentPage; }
        function isCurrentAnchor(e) { return e.isCurrentAnchor; }

        let children = _.chain(items)
            .filter('isVisibleMobile')
            .map(function (item) {
                const linkType = _.get(item, ['link', 'type']);
                const entry = {item};
                entry.isCurrentPage = _.get(item, 'isSelected', false) || linkType === 'PageLink' && getPageId.call(item) === options.currentPage; // eslint-disable-line no-mixed-operators
                entry.isCurrentAnchor = options.currentAnchor && (linkType === 'AnchorLink' && _.get(item, ['link', 'anchorDataId', 'id']) === options.currentAnchor);
                return entry;
            })
            .reduce(function (result, entry) {
                if (options.currentAnchor) {
                    if (entry.isCurrentAnchor) {
                        const currentPageLink = _.find(result, isCurrentPage);
                        if (currentPageLink) {
                            currentPageLink.isCurrentPage = false;
                        }
                    } else if (entry.isCurrentPage) {
                        if (_.find(result, isCurrentAnchor)) {
                            entry.isCurrentPage = false;
                        }
                    }
                }

                result.push(entry);

                return result;
            }, [])
            .map(function (entry) {
                return santaComponents.utils.createReactElement(TinyMenuItem, {
                    menuItem: entry.item,
                    current: entry.isCurrentPage || entry.isCurrentAnchor,
                    options,
                    key: `item${entry.item.id}`
                });
            })
            .value();

        if (options.useSeparators) {
            const childrenWithSeparators = [];
            for (let i = 0; i < children.length; ++i) {
                childrenWithSeparators.push(children[i]);
                if (i < children.length - 1) {
                    childrenWithSeparators.push(santaComponents.utils.createReactElement(
                        'div',
                        {className: `${options.styleId}_separator`, key: `separator_${i}`}
                    ));
                }
            }

            children = childrenWithSeparators;
        }

        return children;
    }

    function getItemCounterPart(counter, styleId, id) {
        if (!_.isNumber(counter)) {
            return null;
        }
        const formattedCounter = counter >= 1000 ? `${parseInt(counter / 1000, 10)}k` : counter;
        return santaComponents.utils.createReactElement('span', {
            key: `counter${id}`,
            className: cssUtils.concatenateStyleIdToClassList(styleId, ['counter'])
        }, `(${formattedCounter})`);
    }

    const TinyMenuItem = createReactClass({
        displayName: 'TinyMenuItem',

        propTypes: {
            menuItem: PropTypes.object,
            options: PropTypes.object,
            current: PropTypes.bool
        },

        statics: {
            buildChildren
        },

        getInitialState: getState,

        onSubMenuToggle(e) {
            this.onSubMenuClick(e);
            e.stopPropagation();
        },

        onSubMenuClick(e) {
            const isOpen = !this.state.isSubMenuOpen;
            this.setState({
                isSubMenuOpen: isOpen
            }, function () {
                if (isOpen && this.props.options.scrollIntoView) {
                    const subMenuDOM = ReactDOM.findDOMNode(this);
                    if (_.isFunction(subMenuDOM.scrollIntoView)) {
                        subMenuDOM.scrollIntoView();
                    }
                }
            });

            if (!this.props.menuItem.link) {
                e.stopPropagation();
            }
        },

        componentWillReceiveProps(nextProps) {
            if (this.props.options.timestamp !== nextProps.options.timestamp) {
                this.setState(getState(nextProps));
            }
        },

        render() { // eslint-disable-line complexity
            const menuItem = this.props.menuItem,
                hasSubMenu = _.some(menuItem.items, 'isVisibleMobile'),
                clickCallback = hasSubMenu ? this.onSubMenuClick : menuItem.clickCallback || this.props.options.clickCallback,
                id = menuItem.id,
                options = this.props.options;

            function getClass(className) {
                return cssUtils.concatenateStyleIdToClassName(options.styleId, className);
            }

            const linkNode = santaComponents.utils.createReactElement('a', _.merge({
                children: _.compact([menuItem.label, _.isNumber(menuItem.displayCount) && getItemCounterPart(menuItem.displayCount, options.styleId, id)]),
                className: cssUtils.concatenateStyleIdToClassList(options.styleId, ['link', this.props.current && 'current']),
                onClick: clickCallback,
                key: `link${id}`
            }, _.get(menuItem, ['link', 'render'])));

            if (!hasSubMenu) {
                return santaComponents.utils.createReactElement('li', {
                    className: getClass('item')
                }, linkNode);
            }

            const subMenuToggleNode = santaComponents.utils.createReactElement('span', {
                className: cssUtils.concatenateStyleIdToClassList(options.styleId, ['toggler', this.props.current && 'current']),
                key: `toggler${id}`,
                onClick: this.onSubMenuToggle
            });

            const subMenuNode = santaComponents.utils.createReactElement('ul', {
                key: `subMenu${id}`,
                ref: 'subMenuContainer',
                className: getClass('submenu')
            }, buildChildren(menuItem.items, _.defaults({useSeparators: false}, options)));

            const headerNodes = [linkNode, subMenuToggleNode];

            return santaComponents.utils.createReactElement('li', {
                key: `item${this.props.menuItem.id}`,
                children: (options.useSeparators ? [
                    santaComponents.utils.createReactElement('div', {
                        className: getClass('header'),
                        key: `header${id}`
                    }, headerNodes)
                ] : headerNodes).concat([subMenuNode]),
                className: cssUtils.concatenateStyleIdToClassList(options.styleId, ['item', 'has-children', this.state.isSubMenuOpen && 'open'])
            });
        }
    });


    return TinyMenuItem;
});
