define(['santa-components', 'lodash', 'componentsCore', 'coreUtils', 'tinyMenu/utils/tinyMenuMemberSectionUtils', 'tinyMenu/components/tinyMenuItem', 'icon', 'loginSocialBar'], function (santaComponents, _, componentsCore, coreUtils, sectionUtils, TinyMenuItem, icon, loginSocialBar) {
    'use strict';

    const ICON_SIZE = 30;
    const authenticationDialogAppId = 3;

    function getClass(className, styleId) {
        return coreUtils.cssUtils.concatenateStyleIdToClassName(styleId, className);
    }

    /**
     * @class components.TinyMenuMemberSection
     * @extends {core.skinBasedComp}
     */
    const tinyMenuMemberSection = {
        displayName: 'TinyMenuMemberSection',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            compData: santaComponents.santaTypesDefinitions.Component.compData,

            // member props
            logout: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.logout.isRequired,
            isLoggedIn: santaComponents.santaTypesDefinitions.MemberLogin.isLoggedIn.isRequired,
            memberName: santaComponents.santaTypesDefinitions.MemberLogin.memberName.isRequired,
            memberAvatar: santaComponents.santaTypesDefinitions.MemberLogin.memberAvatar,
            memberDefaultAvatar: santaComponents.santaTypesDefinitions.MemberLogin.memberDefaultAvatar,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
            memberMenuItems: santaComponents.santaTypesDefinitions.MemberLogin.menuItems,
            memberIconItems: santaComponents.santaTypesDefinitions.MemberLogin.iconItems,
            svgPropsForMemberLoginIconItems: santaComponents.santaTypesDefinitions.VectorImage.svgPropsForMemberLoginIconItems,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
            showAuthenticationDialog: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.showAuthenticationDialog.isRequired,
            previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(loginSocialBar.loginSocialButton),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(icon)
        ),

        onLogout(event) {
            if (this.getLogoutFunction) {
                const logout = this.getLogoutFunction(event);
                if (_.isFunction(logout)) {
                    logout.call(this);
                }
            } else {
                this.props.logout(this.props.data.language);
                if (_.isFunction(this.props.options.clickCallback)) {
                    this.props.options.clickCallback();
                }
            }
        },

        onLogin(event) {
            if (this.getLoginFunction) {
                const login = this.getLoginFunction(event);
                if (_.isFunction(login)) {
                    login.call(this);
                }
            } else {
                this.props.showAuthenticationDialog({
                    language: this.props.data.language,
                    appId: authenticationDialogAppId
                });
            }
        },

        getMemberItems() {
            return sectionUtils.getMemberItems({
                isLoggedIn: this.props.isLoggedIn,
                loggedOutText: this.props.data.loggedOutText,
                logOutText: this.props.data.logOutText,
                memberName: this.props.memberName,
                onLogin: this.onLogin,
                onLogout: this.onLogout,
                linkRenderInfo: this.props.linkRenderInfo,
                rootNavigationInfo: this.props.rootNavigationInfo,
                menuItems: this.props.data.menuItemsRef,
                iconItems: this.props.data.iconItemsRef,
                mergeIconItemsIntoMenuItems: this.props.mergeIconItemsIntoMenuItems
            });
        },

        getMenuItemsSection(menuItems) {
            return TinyMenuItem.buildChildren(menuItems, this.props.options);
        },

        getAvatarAndIconsSection(iconItems, sectionLayoutConfig) {
            if (this.props.mergeIconItemsIntoMenuItems) {
                return null;
            }

            let iconsBlock = null;
            let iconComponents = [];

            if (iconItems) {
                const iconItemComponents = _.map(iconItems, this.getIconItemComponent);
                iconComponents = iconComponents.concat(iconItemComponents);
            }

            if (sectionLayoutConfig === 'avatarAndText' || sectionLayoutConfig === 'avatarOnly') {
                iconComponents.push(this.getAvatarComponent());
            }


            if (this.props.textAlignment === 'left') {
                iconComponents.reverse();
            }


            if (iconComponents.length) {
                iconsBlock = santaComponents.utils.createReactElement('li', {
                    key: `iconBlock${this.props.id}`,
                    className: getClass('memberIconItems', this.props.options.styleId)
                }, iconComponents);
            }
            return iconsBlock;
        },

        getAvatarComponent() {
            let compData;
            let svgProps = null;

            if (this.props.memberAvatar) {
                compData = {
                    uri: this.props.memberAvatar,
                    type: 'Image'
                };
            } else {
                compData = {
                    svgId: this.props.memberDefaultAvatar,
                    type: 'VectorImage'
                };
                svgProps = _.get(this.props.svgPropsForMemberLoginIconItems, this.props.memberDefaultAvatar, null);
            }

            return this.createChildComponent(compData, 'wysiwyg.viewer.components.Icon', 'avatar', {
                ref: 'avatar',
                id: `${this.props.id}avatar`,
                key: 'avatar',
                iconSize: ICON_SIZE,
                style: {
                    width: ICON_SIZE,
                    height: ICON_SIZE
                },
                svgProps,
                className: getClass('avatar', this.props.options.styleId)
            });
        },

        getIconItemComponent(iconItem, index) {
            let svgProps = null;
            const svgId = _.get(iconItem, 'iconRef.svgId');
            if (svgId) {
                svgProps = _.get(this.props.svgPropsForMemberLoginIconItems, svgId, null);
            }

            return this.createChildComponent(this.props.compData, 'wysiwyg.viewer.components.LoginSocialButton', 'button',
                _.assign(_.clone(iconItem), {
                    key: `button${index}`,
                    ref: `button${index}`,
                    id: `${this.props.id}button${index}`,
                    style: {
                        width: ICON_SIZE,
                        height: ICON_SIZE
                    },
                    linkRenderInfo: this.props.linkRenderInfo,
                    rootNavigationInfo: this.props.rootNavigationInfo,
                    iconSize: ICON_SIZE,
                    className: getClass('icon', this.props.options.styleId),
                    svgProps
                })
            );
        },

        getMemberSection() {
            let sections = [];
            const memberItems = this.getMemberItems();
            const avatarAndIcons = this.getAvatarAndIconsSection(memberItems.iconItems, this.props.data.loggedInMember);
            if (avatarAndIcons) {
                sections.push(avatarAndIcons);
            }
            if (memberItems.menuItems) {
                sections = sections.concat(this.getMenuItemsSection(memberItems.menuItems));
            }
            return sections;
        },

        getSkinProperties() {
            const memberSection = this.getMemberSection();

            return {
                '': {
                    children: santaComponents.utils.createReactElement('ul', {
                        children: memberSection,
                        className: this.props.sectionClassName,
                        ref: 'memberSection',
                        id: 'memberSection'
                    }),
                    'data-icon-size': ICON_SIZE,
                    'data-is-logged-in': this.props.isLoggedIn
                }
            };
        }
    };

    componentsCore.compRegistrar.register(
        'wysiwyg.viewer.components.mobile.TinyMenuMemberSection',
        tinyMenuMemberSection
    );

    return tinyMenuMemberSection;
});
