define([
    'lodash',
    'santa-components',
    'componentsCore',
    'coreMultilingual',
    'coreUtils'
],
function (_, santaComponents, componentsCore, coreMultilingual, coreUtils) {
    'use strict';

    const tinyMenuLanguageSection = {
        displayName: 'TinyMenuLanguageSection',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            compData: santaComponents.santaTypesDefinitions.Component.compData,

            scriptsLocationMap: santaComponents.santaTypesDefinitions.ServiceTopology.scriptsLocationMap,
            currentLanguage: santaComponents.santaTypesDefinitions.RendererModel.multilingual.currentLanguage,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired,
            languages: santaComponents.santaTypesDefinitions.RendererModel.multilingual.languages,
            setCurrentLanguageCode: santaComponents.santaTypesDefinitions.RendererModel.multilingual.setCurrentLanguageCode,
            isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode.isRequired
        }),

        translate(translationKey) {
            return coreUtils.translationsLoader.getTranslation('MULTILINGUAL', this.props.userLanguage, translationKey);
        },

        getIconUrl() {
            return coreMultilingual.utils.getFlagIconUrl(this.props.scriptsLocationMap['linguist-flags'], coreMultilingual.flagCountryIconType.square, this.props.currentLanguage.countryCode);
        },

        getOptions() {
            const languages = this.props.languages;
            if (this.props.isPreviewMode) {
                return languages;
            }
            return _.filter(languages, {status: coreMultilingual.languageStatus.ACTIVE});
        },

        getSkinProperties() {
            const {setCurrentLanguageCode} = this.props;
            const getLanguageNameOrCodeDependingOnIfInEditor = lang => this.props.componentViewMode === 'editor' ? lang.name : lang.languageCode.toUpperCase();

            return {
                countryCode: {
                    children: getLanguageNameOrCodeDependingOnIfInEditor(this.props.currentLanguage)
                },
                flagImg: {
                    src: this.getIconUrl()
                },
                element: {
                    className: this.props.sectionClassName
                },
                languageSelect: {
                    value: this.props.currentLanguage.languageCode,
                    onChange(e) {
                        const newLang = e.target.value;
                        setCurrentLanguageCode(newLang);
                    },
                    children: this.getOptions().map(a => santaComponents.utils.createReactElement('option', {
                        value: a.languageCode
                    }, getLanguageNameOrCodeDependingOnIfInEditor(a)))
                }
            };
        }
    };

    componentsCore.compRegistrar.register(
        'wysiwyg.viewer.components.mobile.TinyMenuLanguageSection',
        tinyMenuLanguageSection
    );

    return tinyMenuLanguageSection;
});
