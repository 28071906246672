define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils
) {
    'use strict';

    // params: {
    //     isLoggedIn: 'boolean',
    //     loggedOutText: 'string',
    //     logOutText: 'string',
    //     memberName: 'string',
    //     onLogin: 'function',
    //     onLogout: 'function',
    //     linkRenderInfo: 'object',
    //     rootNavigationInfo: 'object',
    //     menuItems: 'array' | null,
    //     iconItems: 'array' | null,
    //     mergeIconItemsIntoMenuItems: 'boolean'
    // };
    function getMemberItems(params) {
        const memberItems = {
            menuItems: getMenuItems(params),
            iconItems: getIconItems(params)
        };

        return memberItems;
    }

    function getMenuItems(params) {
        if (!params.isLoggedIn) {
            const loginItem = getLoginItem(params.loggedOutText, params.onLogin);
            return [loginItem];
        }

        const welcomeItem = getWelcomeItem(params.memberName);
        const logoutItem = getLogoutItem(params.logOutText, params.onLogout);
        const subItems = params.mergeIconItemsIntoMenuItems ? mergeItems(params.menuItems, params.iconItems) : params.menuItems;
        welcomeItem.items = getItemsWithRender(subItems, params.linkRenderInfo, params.rootNavigationInfo);
        welcomeItem.items.push(logoutItem);

        return [welcomeItem];
    }

    function mergeItems(menuItems, iconItems) {
        const mergedItems = menuItems || [];
        if (!iconItems) {
            return mergedItems;
        }
        const enchancedIconItems = _.map(iconItems, function (iconItem, index) {
            return _.defaults({}, iconItem, {
                id: `iconItem${index}`,
                isVisible: true,
                isVisibleMobile: true,
                items: []
            });
        });

        return mergedItems.concat(enchancedIconItems);
    }

    function getIconItems(params) {
        let menuIcons = null;
        if (params.isLoggedIn && params.iconItems && !params.mergeIconItemsIntoMenuItems) {
            menuIcons = getItemsWithRender(params.iconItems, params.linkRenderInfo, params.rootNavigationInfo);
        }

        return menuIcons;
    }

    function getLoginItem(logInText, onLogin) {
        return {
            id: 'logIn',
            link: null,
            label: logInText,
            isVisible: true,
            isVisibleMobile: true,
            items: [],
            clickCallback: onLogin
        };
    }

    function getWelcomeItem(memberName) {
        return {
            id: 'welcomeUser',
            link: null,
            label: memberName,
            isVisible: true,
            isVisibleMobile: true,
            items: []
        };
    }

    function getLogoutItem(logOutText, onLogout) {
        return {
            id: 'logOut',
            link: null,
            label: logOutText,
            isVisible: true,
            isVisibleMobile: true,
            items: [],
            clickCallback: onLogout
        };
    }

    function getItemsWithRender(items, linkRenderInfo, rootNavigationInfo) {
        return _.map(items, function (item) {
            const itemWithRender = _.cloneDeep(item);
            if (item.link) {
                itemWithRender.link.render = coreUtils.linkRenderer.renderLink(item.link, linkRenderInfo, rootNavigationInfo);
            }
            return itemWithRender;
        });
    }

    return {
        getMemberItems
    };
});
